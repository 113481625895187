import $ from 'jquery';
import InitializeCommand from 'picnic/core/commands/Initialize';
import {getUTM} from 'app/utils/utm';


const
	UTM_MAPPINGS = [
		{
			contactFormStr: '/kontaktformular-pro/',
		},
		{
			contactFormStr: '/kontaktformular-immobilienprofis/',
			utmSourceMappings: {
				linkedin: 'li2'
			}
		},
		{
			contactFormStr: '/kontaktformular-pro-mobel/',
			utmSourceMappings: {
				'print_ad': 'kontaktformular-pro-mobel-pa',
				'print_advertorial': 'kontaktformular-pro-mobel-ppr',
				'digital_advertorial': 'kontaktformular-pro-mobel-dpr',
				'digital_standalone_nl': 'kontaktformular-pro-mobel-snl',
				'digital_newsletter': 'kontaktformular-pro-mobel-nl',
				'digital_whitepaper': 'kontaktformular-pro-mobel-wp',
				'print_directmail': 'kontaktformular-pro-mobel-dm',
			}
		}
	]
;


class Command extends InitializeCommand {
	execute() {
		// add utm source to url for contact form links
		const
			params = new URLSearchParams(window.location.search),
			utmSource = params.get('utm_source')
		;

		if (utmSource) {
			UTM_MAPPINGS.forEach((
				{contactFormStr, utmSourceMappings}
			) => {
				$(`a[href*="${contactFormStr}"]`).each(function () {
					// Just pass UTM params if no mappings are defined
					this.href = `${this.href}${this.href.match(/\?/) ? '&' : '?'}${getUTM()}`;

					if (!utmSourceMappings) {
						return;
					}

					const suffix = utmSourceMappings[utmSource] || '';

					if (!suffix) {
						return;
					}

					this.href = this.href.replace(
						contactFormStr,
						`${contactFormStr}${suffix}/`
					);
				});
			});
		}
	}

}

export default Command;
