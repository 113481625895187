import $ from 'jquery';
import TemplateView from 'picnic/core/views/Template';
import Template from 'app/base/footer/views/Button.html';


var
	SELECTOR_HEADER = '.header',
	CLASS_OPEN = 'is-open'
;

class View extends TemplateView {

	get isOpen() {
		return this.$el.hasClass(CLASS_OPEN);
	}

	set isOpen(value) {
		this.$el.toggleClass(CLASS_OPEN, value);
	}

	get template() {
		return Template;
	}

	events() {
		return {
			'click button': '_onClick'
		};
	}

	_replaceText(target) {
		var
			button = $(target),
			newButtonText = button.data('toggle-text'),
			oldButtonText = button.text(),
			toggleText = button.data()
		;

		button.text(newButtonText);
		toggleText.toggleText = oldButtonText;
	}

	_animateScroll() {
		var
			offset =  $(SELECTOR_HEADER).height() || 120
		;

		$('body, html').animate({
			scrollTop: this.$el.offset().top - offset
		}, 500);
	}

	_onClick(event) {
		event.preventDefault();

		this._replaceText(event.target);
		this.$el.attr('aria-hidden', this.isOpen);
		this.isOpen = !this.isOpen;
		this._animateScroll();
	}

}

export default View;
