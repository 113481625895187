import $ from 'jquery';
import BaseView from 'picnic/core/views/Base';
import {copy} from  'app/utils/copy';


const
	CLASS_ACTIVE = 'is-active',
	CLASS_COPIED = 'is-copied',
	WINDOW_WIDTH = 520,
	WINDOW_HEIGHT = 350
;


class View extends BaseView {
	constructor(options) {
		super(options);
		this._onClickOutside = this._onClickOutside.bind(this);
	}

	render() {
		super.render();
		$(window).on('click', this._onClickOutside);
		return this;
	}

	destroy() {
		$(window).off('click', this._onClickOutside);
		super.destroy();
	}

	events() {
		return {
			'click .copy > button': '_onClickCopy',
			'click .facebook > a': '_onClickLink',
			'click .x > a': '_onClickLink',
			'click .sharing-toggle': '_onClickToggle'
		};
	}

	_onClickCopy(e) {
		const $el = $(e.currentTarget);
		e.preventDefault();
		copy(window.location.href);
		$el.addClass(CLASS_COPIED).children().text($el.data('success-text'));
	}

	_onClickLink(e) {
		const
			{outerHeight, outerWidth, screenX, screenY} = window,
			$el = $(e.currentTarget),
			url = $el.attr('href'),
			top = (outerHeight / 2) + screenY - (WINDOW_HEIGHT / 2),
			left = (outerWidth / 2) + screenX - (WINDOW_WIDTH / 2)
		;

		e.preventDefault();
		window.open(
			url,
			'sharer',
			`top=${top}, left=${left}, toolbar=0, status=0, width=${WINDOW_WIDTH}, height=${WINDOW_HEIGHT}`
		);
	}

	_onClickToggle(e) {
		e.preventDefault();
		this.$el.toggleClass(CLASS_ACTIVE);
	}

	_onClickOutside(e) {
		const $el = $(e.target);
		if (!this.$el.is($el) && !$el.closest(this.$el).length) {
			this.$el.removeClass(CLASS_ACTIVE);
		}
	}
}

export default View;
