import {Context} from 'backbone.geppetto';

// Import "base" modules:
import BackToTop from 'app/base/back-to-top/commands/Initialize';
import Header from 'app/base/header/commands/Initialize';
import Footer from 'app/base/footer/commands/Initialize';
import Consent from 'app/base/consent/commands/Initialize';
import Tabfocus from 'app/base/tabfocus/commands/Initialize';
import Tracking from 'app/base/tracking/commands/Initialize';

// generic modules:
import Clickblocker from 'picnic/Clickblocker';
import Overlay from 'picnic/Overlay';
import Picture from 'app/generic/picture/commands/Initialize';
import Sharing from 'app/generic/sharing/commands/Initialize';

// custom code:
import UTMTracker from 'app/custom/commands/Initialize';


// Setup context:
class App extends Context {
	initialize() {
		// Setup tracking data:
		this.wireValue('tracking-analytics:settings', {
			id: window.googleAnalyticsId,
			gtm: window.googleTagManagerId
		});

		// wire initializes commands:
		const
			modules = window.kleinanzeigen.modules || []
		;

		Promise.all(modules.map((moduleName) => {
			return import(/* webpackChunkName: "[request]" */ `app/cms/${moduleName}/commands/Initialize`);
		})).then((importedModules) => {
			const
				modules = importedModules.map((module) => module.default)
			;

			this.wireCommands({
				'application:start': [
					// "base" modules:
					BackToTop,
					Header,
					Footer,
					Consent,
					Tabfocus,
					Tracking,

					// Generic modules:
					Clickblocker,
					Overlay,
					Picture,
					Sharing,

					// Custum Code:
					UTMTracker
				].concat(modules)
			});

			this.dispatch('application:start');
		});
	}
}

// Expose app:
new App();

if (window.CMS !== undefined) {
	window.CMS.$(window).on('cms-content-refresh', function () {
		new App();
	});
}
