import View from 'app/base/back-to-top/views/Back';
import InitializeCommand from 'picnic/core/commands/Initialize';


class Command extends InitializeCommand {

	get settings() {
		return {
			selector: '.back-to-top',
			namespace: 'back-to-top:views',
			viewclass: View
		};
	}

}

export default Command;
