import Initialize from 'picnic/core/commands/Initialize';
import View from 'app/generic/sharing/views/Sharing';


class Command extends Initialize {

	get settings() {
		return {
			selector: '.sharing',
			namespace: 'sharing:views',
			viewclass: View
		};
	}

}

export default Command;
