// import $ from 'jquery';
import BaseView from 'picnic/core/views/Base';
import {injectScript} from 'app/utils/inject';

class View extends BaseView {

	render() {
		super.render();

		const src = this.$el.data('script');

		if (src) {
			injectScript(src);
		}

		return this;
	}

}

export default View;
