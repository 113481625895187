import $ from 'jquery';
import _ from 'underscore';
import BaseView from 'picnic/core/views/Base';


const
	CLASS_ACTIVE = 'is-active',
	OFFSET = 4096
;

class View extends BaseView {

	constructor(options) {
		super(options);
		this._onScroll = _.debounce(this._onScroll, 200);
	}

	get top() {
		this._top = this._top || $('#top').offset().top;
		return this._top;
	}

	render() {
		super.render();
		this._onScroll();
		$(document).on('scroll', this._onScroll.bind(this));
		return this;
	}

	events() {
		return {
			'click .back-to-top-link': '_onClick'
		};
	}

	_onClick(e) {
		e.preventDefault();
		$('body, html').animate({scrollTop: this.top}, 300);
		e.currentTarget.blur();
	}

	_onScroll() {
		const
			{scrollY, innerHeight} = window,
			{scrollTop, scrollHeight} = document.documentElement,
			top = (scrollY || scrollTop) + innerHeight,
			offset = Math.min(scrollHeight / 2)
		;

		if (scrollHeight > OFFSET && top > offset) {
			this.$el.addClass(CLASS_ACTIVE);
		} else {
			this.$el.removeClass(CLASS_ACTIVE);
		}
	}

}

export default View;
