import $ from 'jquery';
// import {TrackPageview, TrackSocial} from 'picnic/TrackingAnalytics';
import AnalyticsInitialize from './AnalyticsInitialize';
import TrackEvent from './TrackEvent';
import RegistryService from '../services/Registry.js';
import OutboundService from 'picnic/TrackingOutbound';


var
	NAMESPACE = 'tracking:',
	NAMESPACE_REGISTRY = `${NAMESPACE}registryservice`,
	NAMESPACE_OUTBOUND = `${NAMESPACE}outboundservice`,
	EVENT_TRACKEVENT = `${NAMESPACE}trackevent`,
	SELECTOR_OUTBOUND = 'a',
	SELECTOR_CUSTOMOUTBOUND = '.custom-tracking',
	OUTBOUND_EVENT_CATEGORY = 'outbound',
	OUTBOUND_EVENT_ACTION = 'link'
;


class Command extends AnalyticsInitialize {
	execute() {
		this._registry = new RegistryService({context: this.context});

		// Wire registry...
		this.context.wireValue(NAMESPACE_REGISTRY, this._registry);

		// Initialize google analytics...
		super.execute();

		// Wire commands for analytics tracking:
		// this.context.wireCommand(EVENT_TRACKPAGEVIEW, TrackPageview);
		this.context.wireCommand(EVENT_TRACKEVENT, TrackEvent);
		// this.context.wireCommand(EVENT_TRACKSOCIAL, TrackSocial);

		// Create outbound service:
		// The outbound service watches all links on a page and when they
		// are clicked, it checks whether it is an internal link or an
		// external link (outbound). In the case of an external link, it
		// fires an event which should be tracked...
		this.context.wireValue(NAMESPACE_OUTBOUND, new OutboundService({
			context: this.context,
			selector: SELECTOR_OUTBOUND,
			eventName: EVENT_TRACKEVENT,
			eventData: {
				category: OUTBOUND_EVENT_CATEGORY,
				action: OUTBOUND_EVENT_ACTION
			}
		}));

		$('body')
			.on(`click${SELECTOR_CUSTOMOUTBOUND}`, SELECTOR_CUSTOMOUTBOUND, ({currentTarget}) => {
				this.context.dispatch('tracking:custom-tracking', {
					category: currentTarget.getAttribute('data-category') || window.kleinanzeigen.trackingIdentifier,
					action: currentTarget.getAttribute('data-action'),
					label: currentTarget.getAttribute('data-label'),
				});
			})
			.on('focusout.input-tracking', 'input', ({currentTarget}) => {
				const element = !$(currentTarget).hasClass('input-tracking') ? $(currentTarget).parents('.input-tracking')[0] : currentTarget;
				if (!element) {
					return;
				}
				this.context.dispatch('tracking:input-tracking', {
					category: element.getAttribute('data-category'),
					action: element.getAttribute('data-action'),
					label: element.getAttribute('data-label') + currentTarget.value.length
				});
			});

		this._registerAccordion();
		this._registerWizard();
		this._registerContact();
		this._registerSlideshow();
		this._registerTracking();
	}

	get trackingIdentifier() {
		return window.kleinanzeigen.trackingIdentifier || 'LandingPageMBK';
	}

	get fieldsObject() {
		return {
			'dimension1': this.trackingIdentifier
		};
	}

	_registerAccordion() {
		this._registry.registerEvent('cms:accordion:toggle', {
			category: '.category',
			action: '.action',
			label: '.label',
			fieldsObject: this.fieldsObject
		});
	}

	_registerWizard() {
		this._registry.registerEvent('realestate:wizard:submit', {
			category: ({dataCategory}) => dataCategory,
			action: ({dataAction}) => dataAction,
			label: ({dataLabel}) => dataLabel,
			fieldsObject: this.fieldsObject
		});
	}

	_registerContact() {
		const {fieldsObject, trackingIdentifier} = this;

		this._registry.registerEvent('contact:success', {
			category: function(data) {
				if (data.dataCategory) {
					return data.dataCategory;
				}
				if (data.category === 'pro' || data.category === 'real-estate') {
					return `LandingPage${((data.category === 'pro') ? 'Pro' : 'RealEstate')}Lead`;
				}
				return trackingIdentifier;
			},
			action: 'Web2LeadSentAttempt',
			label: function(data) {
				if (data.dataLabel) {
					return data.dataLabel;
				}
				if (data.category === 'pro' || data.category === 'real-estate') {
					return (data.category === 'pro') ? 'Pro' : 'RE_B2C';
				}
			},
			fieldsObject
		});

		this._registry.registerEvent('contact:removesuccess', {
			category: trackingIdentifier,
			action: '.action',
			label: '.label',
			fieldsObject
		});

		this._registry.registerEvent('contact:exitintent', {
			category: trackingIdentifier,
			action: 'UserInactive',
			label: '0',
			fieldsObject
		});
	}

	_registerSlideshow() {
		this._registry.registerEvent('slideshow:change', {
			category: this.trackingIdentifier,
			action: 'CarouselClick',
			label: 'Advantage'
		});
	}

	_registerTracking() {
		const {fieldsObject} = this;

		this._registry.registerEvent('tracking:custom-tracking', {
			category: '.category',
			action: '.action',
			label: '.label',
			fieldsObject
		});

		this._registry.registerEvent('tracking:input-tracking', {
			category: '.category',
			action: '.action',
			label: '.label',
			fieldsObject
		});
	}
}

export default Command;
