import $ from 'jquery';
import DEFAULTS from 'picnic/googletagmanager/settings';


const
	NAMESPACE_SETTINGS = 'tracking-analytics:settings'
;


class Command {
	execute() {
		if (!this.settings.gtm) {
			return;
		}

		(function (w, d, s, l, i) {
			w[l] = w[l] || [];
			w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
			const
				f = d.getElementsByTagName(s)[0],
				j = d.createElement(s),
				dl = l !== 'dataLayer' ? `&l=${l}` : ''
			;
			j.async = true;
			j.src = `https://server.sgtm-legacy.kleinanzeigen.de/gtm.js?id=${i}${dl}`;
			f.parentNode.insertBefore(j, f);
		})(window, document, 'script', 'dataLayer', this.settings.gtm);

		const event = {
			event: 'page_meta',
			page_type: window.kleinanzeigen.trackingIdentifier || 'LandingPageMBK'
		};

		// If the user has not given consent to Google Analytics, we push the event to a temporary dataLayer
		if (!window?.ekGdprConsentBanner?.internal?.isGoogleAnalyticsConsentGiven()) {
			window._dataLayer = window._dataLayer || [];
			window._dataLayer.push(event);

			// If consent is given, push all events from the temporary dataLayer to the real dataLayer
			document.addEventListener('consentInterpretationChanged', function(e) {
				if (e?.detail?.consentInterpretation?.googleAnalyticsAllowed && window?._dataLayer?.length) {
					window._dataLayer.forEach((event) => window.dataLayer.push(event));
					delete window._dataLayer;
				}
			});

			return;
		}

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push(event);
	}

	get settings() {
		let settings = $.extend({}, DEFAULTS);

		// Load possible settings from registered plugins:
		if (this.context.hasWiring(NAMESPACE_SETTINGS)) {
			settings = $.extend(settings, this.context.getObject(NAMESPACE_SETTINGS));
		}

		return settings;
	}
}

export default Command;
