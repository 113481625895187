import View from 'picnic/core/views/Base';


const
	__observers = {},
	__references = new WeakMap(),
	__noop = () => {/* no operation */},
	SETTINGS = {
		rootMargin: '0px',
		threshold: [0, 0.0001, 0.9999, 1],

		onVisible: __noop,
		onInvisible: __noop
	}
;


function __getKey(instance) {
	const {rootMargin, threshold} = instance.settings;
	return `rm:${rootMargin}|th:${threshold.join(',')}`;
}

function __observe(instance) {
	const
		key = __getKey(instance),
		observer = __observers[key] || new IntersectionObserver(
			__onIntersect,
			instance.settings
		)
	;

	__observers[key] = observer;
	__references.set(instance.observable, {instance, observer});
	observer.observe(instance.observable);
}

function __unobserve(instance) {
	const
		key = __getKey(instance),
		observer = __observers[key]
	;

	if (!observer) {
		return;
	}

	observer.unobserve(instance.observable);
	__references.delete(instance.observable);
}

function __onIntersect(entries) {
	entries.forEach((entry) => {
		const
			{instance} = __references.get(entry.target) || {},
			{settings} = instance || {settings: {...SETTINGS}},
			callback = entry.isIntersecting ?
				settings.onVisible :
				settings.onInvisible
		;

		if (callback) {
			callback.call(instance, entry);
		}
	});
}


export class IntersectionView extends View {

	get settings() {
		return {...SETTINGS};
	}

	get observable() {
		return this.el;
	}

	render() {
		super.render();
		__observe(this);
		return this;
	}

	unobserve() {
		__unobserve(this);
	}

	destroy() {
		__unobserve(this);
		return super.destroy();
	}

}


export function clearInstances() {
	Object.keys(__observers).forEach((key) => delete(__observers[key]));
}
