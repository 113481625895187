import InitializeCommand from 'picnic/core/commands/Initialize';
import View from 'app/base/header/views/Header';


class Command extends InitializeCommand {

	get settings() {
		return {
			selector: '.header',
			namespace: 'header:views',
			viewclass: View
		};
	}

}

export default Command;
