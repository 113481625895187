import Initialize from 'picnic/core/commands/Initialize';
import View from 'app/generic/picture/views/Picture';


class Command extends Initialize {

	get settings() {
		return {
			selector: '.picture',
			namespace: 'picture:views',
			viewclass: View
		};
	}

}

export default Command;
