import $ from 'jquery';
import _ from 'underscore';
import {IntersectionView} from 'app/generic/intersection/views/Intersection';
import Template from 'app/generic/picture/views/Picture.html';


var
	SELECTOR_PREVIEW = '.preview',
	SELECTOR_IMAGE = 'img',
	CLASS_LOADING = 'is-loading',
	CLASS_LOADING_COMPLETE = 'is-loaded',
	CLASS_COMPLETE = 'is-completed',
	ATTR_SRCSET = 'data-srcset',
	ATTR_SRCSET2X = 'data-srcset2x',
	ATTR_ALT = 'alt',
	EVENT_LOAD = 'load',
	EVENT_TRANSITIONEND = [
		'webkitTransitionEnd',
		'msTransitionEnd',
		'oTransitionEnd',
	].reduce((acc, eventName) => {
		const handler = `on${eventName.toLowerCase()}`;
		return (handler in window) ? eventName : acc;
	}, 'transitionend'),
	template = _.template(Template)
;


class View extends IntersectionView {

	constructor(options) {
		super(options);

		this._onLoad = this._onLoad.bind(this);
		this._onComplete = this._onComplete.bind(this);
		this._onVisible = this._onVisible.bind(this);
	}

	get settings() {
		return {
			...super.settings,
			onVisible: this._onVisible,
		};
	}

	_load() {
		var
			base64picture = this.$el.find(SELECTOR_IMAGE),
			srcset2x = base64picture.attr(ATTR_SRCSET2X),
			srcset = base64picture.attr(ATTR_SRCSET),
			alt = base64picture.attr(ATTR_ALT)
		;

		if (!srcset) {
			// The picture seems to be an external picture, stop loading of
			// sourcesets here...
			return;
		}

		// Build complete sourceset list...
		srcset2x = srcset2x && srcset2x.split(',');
		srcset = srcset.split(',').map((item, index) => {
			var
				src = $.trim(item).split(' '),
				src2x = srcset2x && $.trim(srcset2x[index]).split(' ')
			;

			return {
				src: {
					url: src[0],
					width: src[1].toLowerCase().replace('w', '')
				},
				src2x: src2x && {
					url: src2x[0],
					width: src2x[1].toLowerCase().replace('w', '')
				} || null
			};
		});

		// Render picture element and load sourceset.
		this.$el.addClass(CLASS_LOADING);
		this._picture = $(template({srcset: srcset, alt: alt}));
		this._picture.find(SELECTOR_IMAGE).on(EVENT_LOAD, this._onLoad);
		this._picture.appendTo(this.el);
	}

	_onLoad(event) {
		this.$el
			.removeClass(CLASS_LOADING)
			.addClass(CLASS_LOADING_COMPLETE);

		this._picture.on(EVENT_TRANSITIONEND, this._onComplete);
		event.target.removeEventListener(EVENT_LOAD, this._onLoad);
	}

	_onComplete() {
		this.$el
			.removeClass(CLASS_LOADING_COMPLETE)
			.addClass(CLASS_COMPLETE)
			.find(SELECTOR_PREVIEW)
			.remove();

		this._picture.off(EVENT_TRANSITIONEND, this._onComplete);
	}

	_onVisible() {
		this.unobserve();
		this._load();
	}
}

export default View;
