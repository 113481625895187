import Initialize from 'picnic/core/commands/Initialize';
import View from '../views/Consent';


class Command extends Initialize {
	get settings() {
		return {
			selector: '#consentBanner, #consentManagementPage',
			namespace: 'consent:views',
			viewclass: View
		};
	}
}

export default Command;
