export function getUTM() {
	const
		params = new URLSearchParams(window.location.search),
		utm = []
	;

	params.forEach((value, key) => {
		if (key.startsWith('utm_')) {
			utm.push(`${key}=${value}`);
		}
	});

	return utm.join('&');
}
