export function injectScript(src, callback) {
	var script = document.createElement('script');
	script.type = 'text/javascript';
	script.charset = 'utf-8';
	script.async = true;
	script.defer = true;
	script.src = src;
	script.onload = callback || null;
	document.body.appendChild(script);
}
