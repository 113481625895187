import View from 'app/base/footer/views/Footer';
import InitializeCommand from 'picnic/core/commands/Initialize';


class Command extends InitializeCommand {

	get settings() {
		return {
			selector: '.footer .alphabetic-list',
			namespace: 'footer:views',
			viewclass: View
		};
	}

}

export default Command;
