import _ from 'underscore';


class Command {
	execute() {
		const
			{action, category, label, value, fieldsObject} = this.eventData
		;

		if (!category) {
			throw new Error('Missing category for trackevent call');
		}

		if (!action) {
			throw new Error('Missing action for trackevent call');
		}

		if (value && !label) {
			throw new Error('The add a label for trackevent call when sending a value');
		}

		if (value && !_.isNumber(value)) {
			throw new Error('The value to send must be type of number for trackevent call');
		}

		if (fieldsObject && !_.isObject(fieldsObject)) {
			throw new Error('The fieldsObject to send must be type of object for trackevent call');
		}

		var event = {
			event: 'gaEvent',
			eventAction: action,
			eventCategory: category,
			eventLabel: label,
			eventValue: value,
			fieldsObject
		};

		// If the user has not given consent to Google Analytics, we push the event to a temporary dataLayer
		if (!window?.ekGdprConsentBanner?.internal?.isGoogleAnalyticsConsentGiven()) {
			window._dataLayer = window._dataLayer || [];
			window._dataLayer.push(event);
			return;
		}

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push(event);
	}
}

export default Command;
